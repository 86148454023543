import React from "react"
import { Link } from "gatsby"
import Icon from "../icon/icon"

const HeaderBanner = props => (
    <div className={'w100 left p15 col-xs-w50 col-xs-pl5 col-xs-pr5 col-xs-pt5 col-xs-pb5 col-xs-h50 ' + props.classAdd}>
        <Link className="w100 h100 left b-white bn p25 b-ra5 bs3 t-center colored2-bg fs24 lh30 soft owh pos-r center-h smartbox" to={props.linkTo}>
            <span className="center-w col-xs-fs18 col-xs-lh18">
                <Icon icon={props.icon} classAdd={'icon white p25 pt15 pb15 fs50 lh50 col-xs-fs35 col-xs-lh35 center-w '}></Icon>
                <span className="w100 left t-center white op8 ceviri_def">{props.title}</span>
            </span>
            <i className="icon icon-add colored3 op1 fs42 lh42 col-xs-fs32 col-xs-lh32 pos-a ll15 rtanimate1" style={{ top: "-5px" }}></i>
            <i className="icon icon-add colored3 op2 fs42 lh42 col-xs-fs32 col-xs-lh32 pos-a lr15 rtanimate4" style={{ bottom: "-5px" }}></i>
            <i className="icon icon-add colored3 op2 fs42 lh42 col-xs-fs32 col-xs-lh32 pos-a rtanimate2" style={{ bottom: "-5px", left: "10%" }}></i>
            <i className="icon icon-add colored3 op1 fs42 lh42 col-xs-fs32 col-xs-lh32 pos-a rtanimate3" style={{ top: "-5px", right: "10%" }}></i>
            <i className="icon icon-add colored3 op3 fs42 lh42 col-xs-fs32 col-xs-lh32 pos-a rtanimate4" style={{ top: "15px", left: "20%" }}></i>
            <i className="icon icon-add colored3 op2 fs42 lh42 col-xs-fs32 col-xs-lh32 pos-a rtanimate1" style={{ botom: "15px", right: "20%" }}></i>
            <i className="icon icon-add colored3 op1 fs100 lh100 col-xs-fs60 col-xs-lh60 pos-a rtanimate2" style={{ botom: "15px", right: "30%" }}></i>
            <i className="icon icon-add colored3 op1 fs100 lh100 col-xs-fs60 col-xs-lh60 pos-a rtanimate2" style={{ top: "15px", left: "30%" }}></i>
            <i className="icon icon-add colored3 op2 fs100 lh100 col-xs-fs60 col-xs-lh60 pos-a rtanimate4" style={{ top: "20%", left: "10%" }}></i>
            <i className="icon icon-add colored3 op1 fs100 lh100 col-xs-fs60 col-xs-lh60 pos-a rtanimate4" style={{ bottom: "20%", right: "10%" }}></i>
            <span className="w100 left t-center white pos-a ll0 lb0 fs16 lh16 op0 soft-slow motto col-xs-none minmotto ceviri_def">{props.description}</span>
        </Link>
    </div>
)

export default HeaderBanner