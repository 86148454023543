import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderBanner from "../components/index/header_jumbotron"
import CategoryTitle from "../components/index/categorytitle"


const Cozumler = () => {
   return (
        <Layout>
            <SEO title="Решения" />
            <div id="cozumler" className="inlinePage center-h footerFixed">
                <div className="container-fluid w80 col-xs-w95">
                    <CategoryTitle title="SERENAY" subTitle="Наши решения" description="Наши решения" isDark={false} position="inline" />
                    <div className="row">
                            <div className="flex w100 left h100 col-xs-block customHeight">
                                <HeaderBanner title="Пользовательские Программные Решения" description="" icon="icon-code1" linkTo="/специальное-программное-обеспечение" />
                                <HeaderBanner title="Выделенные Серверные Службы" description="" icon="icon-web-hosting" linkTo="/Выделенные-Серверные-Службы" />
                                <HeaderBanner title="Технологии, Которые Мы Используем" description="" classAdd="col-xs-w100" icon="icon-heart2" linkTo="/технологии" />
                            </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Cozumler
